import React, {useEffect, useState} from 'react';
import './TableInitiative.css'
import {Empty, Form, Input, Table, Tooltip} from "antd";
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import moment from 'moment'
import {lanGs} from "../../utils/lang/langTableInitiative";
import {sendMetrik} from "../../utils/Analitics/metriks";
const TableInitiative = ({lang, data, setClickInitiative , setResetTable, moder, tabBtn}) => {
    const [tableItems, setTableItems] = useState(null)
    const [searchText, setSearchText] = useState(null);
    const [searchContractor, setSearchContractor] = useState(null);
    const [column, setColumn] = useState(null);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    useEffect(() => {
        setTableItems(data)
    }, [data, lang])
    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const searchValue = (item, column, lang) => {
        switch (column) {
            case'name':
                return searchText ? item?.filter(i => i?.name?.toLowerCase().includes(searchText?.toLowerCase())) : item
            default :
                return item
        }
    }

    let dobleChild = (item) => {
        switch (item) {
            case 'district':
                return (data?.map((i) => i[item])?.filter((elem, index, self) =>
                    index === self.findIndex((e) => e?.id === elem?.id)).map((i) => {
                    return i ? {text: i[lang], value: i.id,} : {text: lanGs['Не указан'][lang], value: 0,};
                }));
            case 'status':
                return (data?.map((i) => i[item])?.filter((elem, index, self) =>
                    index === self.findIndex((e) => e?.id === elem?.id)).map((i) => {
                    return i ? {text: i[lang], value: i.id,} : {text: lanGs['Не указан'][lang], value: 0,};
                }));
            default: return (data?.map((i) => i[item])?.filter((elem, index, self) =>
                index === self.findIndex((e) => e?.id === elem?.id)).map((i) => {
                return i ? {text: i?.name[lang], value: i?.id,} : {text: lanGs['Не указан'][lang], value: 0,};
            }));
        }
    };
    const handleReset = (item) => {
        switch (item) {
            case 'name':
                return setSearchText(null)
            default:
                return setSearchContractor(null)
        }
    };
    const [form] = Form.useForm();
    const onReset = () => form.resetFields()
    const getColumnSearchProps = (item) => {
        switch (item) {
            case 'name':
                return {
                    filterDropdown: () => (
                        <div style={{padding: 8,}} className={'modal_item_search'}>
                            <Form form={form}>
                                <Form.Item name="name">
                                    <Input
                                        className={'input_table_search'}
                                        placeholder={lanGs['Поиск по наименованию'][lang]}
                                        onChange={(e) => {
                                            setColumn('name')
                                            setSearchText(e.target.value ? e.target.value : null)
                                        }}
                                        allowClear
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    ),
                    filterIcon: () => (!searchText ?
                        <SearchOutlined/> :
                        <CloseOutlined style={{color: searchText ? '#1890ff' : undefined,}} onClick={() => {
                            onReset();
                            handleReset(item)
                        }}/>),
                }
            default:break
        }
    }
    const columns = [
        {
            title: (<Tooltip title={'№'}><span className={"table_icon_head"}>{'№'}</span></Tooltip>),
            dataIndex: `id`,
            key: "id",
            width: "80px",
            sorter: {compare: (a, b) => a.id - b.id,},
            sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
        },
        {
            title: (<Tooltip title={lanGs['Наименование'][lang]}><span className={"table_icon_head"}>{lanGs['Наименование'][lang]}</span></Tooltip>),
            dataIndex: `name`,
            render: (item, r) => <span className={`line_item first_item colorID}`}>{item}</span>,
            key: "name", ...getColumnSearchProps('name'),
            sorter: (a, b) => {return  a.name.localeCompare(b?.name) ;},
            sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,



        },
        {
            title: (<Tooltip title={lanGs['Категория'][lang]}><span className={"table_icon_head"}>{lanGs['Категория'][lang]}</span></Tooltip>),
            dataIndex: `categoryInitiative`,
            render: (item, r) => {return <span title={item?.name[lang]}>{item?.name[lang]}</span>},
            key: "categoryInitiative",
            width: "200px",
          /*  ellipsis: true,*/
            filters: dobleChild("categoryInitiative"),

            onFilter: (value, record) => {
                return record.categoryInitiative ? record.categoryInitiative?.id === value : 0 === value
            },
            sorter: (a, b) => {
                return a?.categoryInitiative?.name ? a?.categoryInitiative.name[lang].localeCompare(b?.categoryInitiative?.name ? b?.categoryInitiative.name[lang] :
                    lanGs['Не указан'][lang]) : lanGs['Не указан'][lang];
            },
            sortOrder: sortedInfo.columnKey === "categoryInitiative" ? sortedInfo.order : null,
        },
        {
            title: (<Tooltip title={''}><span className={"table_icon_head"}>{lanGs['Категория работ'][lang]}</span></Tooltip>),
            dataIndex: `categoryWork`,
            render: (item, r) => {return <span title={item?.name[lang]}>{item?.name[lang]}</span>},
            key: "categoryWork",
            width: "200px",
/*            ellipsis: true,*/
            filters: dobleChild("categoryWork"),
            onFilter: (value, record) => {
                return record.categoryWork ? record.categoryWork?.id === value : 0 === value
            },
            sorter: (a, b) => {
                return a?.categoryWork?.name ? a?.categoryWork.name[lang].localeCompare(b?.categoryWork?.name ? b?.categoryWork.name[lang] :
                    lanGs['Не указан'][lang]) : lanGs['Не указан'][lang];
            },
            sortOrder: sortedInfo.columnKey === "categoryWork" ? sortedInfo.order : null,
        },
        {
            title: (<Tooltip title={''}><span className={"table_icon_head"}>{lanGs['Объект'][lang]}</span></Tooltip>),
            dataIndex: `categoryObject`,
            render: (item, r) => {return <span title={item?.name[lang]}>{item?.name[lang]}</span>},
            key: "categoryObject",
            width: "200px",
            /*ellipsis: true,*/
            filters: dobleChild("categoryObject"),
            onFilter: (value, record) => {
                return record.categoryObject ? record.categoryObject?.id === value : 0 === value
            },
            sorter: (a, b) => {
                return a?.categoryObject?.name ? a?.categoryObject.name[lang].localeCompare(b?.categoryObject?.name ? b?.categoryObject.name[lang] :
                    lanGs['Не указан'][lang]) : lanGs['Не указан'][lang];
            },
            sortOrder: sortedInfo.columnKey === "categoryObject" ? sortedInfo.order : null,
        },
        {
            title: (<Tooltip title={lanGs['Район'][lang]}><span className={"table_icon_head"}>{lanGs['Район'][lang]}</span></Tooltip>),
            dataIndex: `district`,
            render: (item, r) => {
                return <span title={item ? item.id !== 0 ? item[lang]?.slice(0, -6):item[lang]?.slice(2):''}>
                    {item ? item.id !== 0 ? item[lang]?.slice(0, -6): lang === 'nameKz'? item[lang]?.slice(0,-2):item[lang]?.slice(2) :''}
                </span>
            },
            key: "district",

            width: "150px",
            ellipsis: true,
            filters: dobleChild("district"),
            sorter: (a, b) => {
                return a?.district ? a?.district[lang].localeCompare(b?.district ? b?.district[lang] : lanGs['Не указан'][lang]) : lanGs['Не указан'][lang];
            },
            sortOrder: sortedInfo.columnKey === "district" ? sortedInfo.order : null,
            onFilter: (value, record) => {return record.district ? record.district?.id === value : 0 === value},
        },
        {
            title: (<Tooltip title={lanGs['Дата регистрации'][lang]}><span className={"table_icon_head"}>{lanGs['Дата регистрации'][lang]}</span></Tooltip>),
            dataIndex: `date`,
            render: (item, r) => {return item ? item : '';},
            key: "date",
            width: "150px",
            sorter: {compare: (a, b) => moment(a.date, "DD.MM.YYYY") - moment(b.date, "DD.MM.YYYY"),},
            sortOrder: sortedInfo.columnKey === "date" ? sortedInfo.order : null,
        },
        {
            title: (<Tooltip title={lanGs['Голосов'][lang]}><span className={"table_icon_head"}>{lanGs['Голосов'][lang]}</span></Tooltip>),
            dataIndex: `voteAmount`,
            key: "voteAmount",
            width: "100px",
            sorter: {compare: (a, b) => a.voteAmount - b.voteAmount,},
            sortOrder: sortedInfo.columnKey === "voteAmount" ? sortedInfo.order : null,
        },
        /*{
            title: (<Tooltip title={lanGs['Голосов'][lang]}><span className={"table_icon_head"}>{lanGs['Голосов'][lang]}</span></Tooltip>),
            dataIndex: `voteAmount`,
            key: "voteAmount",
            width: "100px",
            sorter: {compare: (a, b) => a.voteAmount - b.voteAmount,},
            sortOrder: sortedInfo.columnKey === "voteAmount" ? sortedInfo.order : null,
        },*/
    ];

    return (
        <div className={'TableInitiative_wrap'}>
            {tableItems ? <Table
                columns={columns}
                dataSource={searchValue(tableItems, column, lang)} className={'TableInitiative_table'}
                scroll={{y:'calc(100vh - 250px)', x: '1450px', }}
                pagination={false}
                scrollToFirstRowOnChange={true}
                onChange={handleChange}
                bordered
                rowKey="id"
                size="middle"
                width="100%"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {

                            setClickInitiative(record.id)
                            sendMetrik('ClickPassportInitiatives', `${record.id}`)
                        }, // click row

                    };
                }}
            />
                : <span className={'NO_DATA'}><Empty/></span>}
        </div>
    );
};

export default TableInitiative;
