import React, {useEffect, useState} from 'react';
import './Initiatives.css'
import axios from "axios";
import HeaderBlock from "./HeaderBlock";
import FooterBlock from "./FooterBlock";
import Cookies from 'js-cookie';
import {Route, Routes, useNavigate} from "react-router-dom";
import LoginBlock from "../auth/LoginBlock";
import RegBlock from "../auth/RegBlock";
import Dashboard from '../../pages/Dashboard'
import ForgotBlock from "../auth/ForgotBlock";
import ChangePasBlock from "../auth/ChangePasBlock";
import ConfirmEmail from "../auth/ConfirmEmail";
import AddInitiatives from "../../pages/AddInitiatives";
import RulesBlock from "../../pages/RulesBlock";
import AboutProject from "../../pages/AboutProject";
import TableBlock from "../../pages/TableBlock";
import NotPage from "../../pages/NotPage";
import {ConfigProvider, message} from "antd";
import 'dayjs/locale/zh-cn';
import rRu from 'antd/locale/ru_RU';
import rKz from 'antd/locale/kk_KZ';
import CardInitiativeBlock from "../element/CardInitiativeBlock";
import WorkMapPage from "../../pages/WorkMapPage";
import IniMapPage from "../../pages/IniMapPage";
import AuthSocial from "../auth/AuthSocial";
import TableBlockModer from "../../pages/TableBlockModer";
import {lanGs} from "../../utils/lang/langResolutionBlock";
import EditInitiatives from "../../pages/EditInitiatives";
import jwtDecode from "jwt-decode";
import ProfilePage from "../profile/ProfilePage";
import EditProfilePage from "../profile/EditProfilePage";
import dayjs from "dayjs";

dayjs.locale('ru');
const UserID = Cookies.get('UserID') ? JSON.parse(Cookies.get('UserID')) : null
const Initiatives = () => {
    const [lang, setLang] = useState(Cookies.get('lang') ? Cookies.get('lang') : "nameRu");
    const [resU, setResU] = useState(false)
    const [catCardClick, setCatCardClick] = useState(null)
    const [userI, setUserI] = useState(UserID)
    const [myAds, setMyAds] = useState(null)
    const [mapCatClick, setMapCatClick] = useState(null)
    const [clickInitiative, setClickInitiative] = useState(null)
    const [to10Data, setTop10Data] = useState(null)
    const [dataCat, setDataCat] = useState(null)
    const [dataCardPas, setDataCardPas] = useState(null)
    const [tabBtn, setTabBtn] = useState(false)
    const [totalInfo, setTotalInfo] = useState(false)
    const [resetTable, setResetTable] = useState(false)
    const [resetCardMain, setResetCardMain] = useState(false)
    const [data, setData] = useState(null)
    const [tabsKey, setTabsKey] = useState(0)
    const [messageApi, contextHolder] = message.useMessage();
    const [distChoice, setDistChoice] = useState({dist: "г. Алматы", id: 0});
    const [archiveYears, setArchiveYears] = useState(null)
    const [isYear, setIsYear] = useState(null)
    const navigate = useNavigate();
    const token = Cookies.get('jwt')
    let CatC = Cookies.get('catC')

    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const tokenExpiry = decodedToken?.exp;

                if (tokenExpiry && Date.now() >= (tokenExpiry * 1000) - 60000) {
                    message.error(`${lanGs['token'][lang]}`, [5]).then(() => {
                        Cookies.remove('jwt');
                        Cookies.remove('roles');
                        navigate('/');
                    });
                }
            } catch (error) {
                console.error("Error decoding token:", error);
                message.error("Invalid token format");
                Cookies.remove('jwt');
                Cookies.remove('roles');
                navigate('/');
            }
        }
    }, [token, lang, navigate]);

    useEffect(() => {
        axios.get(`/sc-initiatives/api/v1/ref/available-years`).then((res) => {
            setArchiveYears(res.data);
            setIsYear(res.data[0])
        })
    }, [])
    /*запрос данных для скрол панели и данных по общему ко-ву заявок*/
    useEffect(() => {
        /*  Cookies.remove('catC');*/
        axios.get(`/sc-initiatives/api/v1/dashboard/top10`).then((res) => {
            setTop10Data(res.data);
        })
        axios.get(`/sc-initiatives/api/v1/dashboard/total-info`).then((res) => {
            setTotalInfo(res.data);
            setResetCardMain(false)
        })

    }, [resetCardMain, resU])
    /* запрос данных для отрисовки карточек основного окна*/
    useEffect(() => {
        // setData(null)
        axios.get(`/sc-initiatives/api/v1/dashboard/category?archived=${tabBtn}&distId=${distChoice.id !== 0 ? distChoice.id : ''}`).then((res) => {
            setDataCat(res.data);
            setResetCardMain(false)
        })

    }, [tabBtn, resetCardMain, distChoice, resU])
    /* запрос данных для отрисовки паспорта инициативы*/
    useEffect(() => {
        clickInitiative && axios({
            method: "get",
            url: `/sc-initiatives/api/v1/initiative/detail/${clickInitiative}`,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        })
            .then((res) => {
                setDataCardPas(res.data);
            })
            .catch(function (error) {
                if (error.request.statusText === 'Unauthorized') {
                    messageApi.open({type: 'error', content: `${lanGs['Unauthorized'][lang]}`, duration: 5,},);
                    setTimeout(function () {
                        Cookies.remove('jwt');
                        navigate('/login')
                    }, 1000)
                }
            });
    }, [clickInitiative])
    useEffect(() => {
        setData(null)
        axios({
            method: "post",
            url: `/sc-initiatives/api/v1/initiative/all`,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
            data: {
                "archived": tabBtn,
                "categoryInitiativeId": catCardClick?.categoryIds ? catCardClick?.categoryIds : CatC,
                "distId": distChoice?.id && distChoice?.id !== 0 ? distChoice.id : null,
                "categoryObjectId": mapCatClick?.objectId ? mapCatClick?.objectId[0] : null,
                "categoryWorkId": mapCatClick?.workId ? mapCatClick.workId : null,
                "year": tabBtn ? isYear : null
            },
        })
            .then((res) => {
                setData(res.data);
                setResetTable(false)
            })
            .catch(function (error) {
                if (error.request.statusText === 'Unauthorized') {
                    messageApi.open({type: 'error', content: `${lanGs['Unauthorized'][lang]}`, duration: 5,},);
                    setTimeout(function () {
                        Cookies.remove('jwt');
                        navigate('/login')
                    }, 1000)
                }
            });
    }, [CatC, resetTable, token, tabBtn, distChoice, catCardClick, mapCatClick, isYear, resU]);
    useEffect(() => {
        userI && token && axios({
            method: "get",
            url: `/sc-initiatives/api/v1/user/initiatives`,
            headers: {"Content-type": "application/json; charset=UTF-8", Authorization: `Bearer ${token}`},
        }).then((res) => {
            setMyAds(res.data)
        })
            .catch(function (error) {
                Cookies.remove('jwt');
                Cookies.remove('UserID');
                Cookies.remove('name');
                Cookies.remove('email');
                Cookies.remove('roles');
                setUserI(null)
                navigate('/')
            });
        setResU(false)
    }, [userI, token, resU]);

    return (<ConfigProvider locale={lang !== 'nameRu' ? rKz : rRu}>
            <div className={'Initiatives_wrapper'}>
                <HeaderBlock lang={lang} setLang={setLang} setUserI={setUserI} userI={userI} setResU={setResU}/>
                <Routes>
                    <Route exact path="/"
                           element={<Dashboard
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               setClickInitiative={setClickInitiative}
                               clickInitiative={clickInitiative}
                               to10Data={to10Data}
                               dataCat={dataCat}
                               tabBtn={tabBtn}
                               setTabBtn={setTabBtn}
                               totalInfo={totalInfo}
                               setResetTable={setResetTable}
                           />}/>
                    <Route exact path="/add-initiatives"
                           element={<AddInitiatives
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               setResetCardMain={setResetCardMain}
                               setData={setData}
                               setDistChoice={setDistChoice}/>}
                    />
                    <Route exact path="/edit-initiatives/:id"
                           element={<EditInitiatives
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               setData={setData}
                               setDistChoice={setDistChoice}/>}
                    />
                    <Route path="/login" element={<LoginBlock
                        lang={lang}
                        setUserI={setUserI}
                        setResU={setResU}/>}
                    />
                    <Route path="/reg"
                           element={<RegBlock
                               lang={lang}/>}/>
                    <Route path="/forgot"
                           element={<ForgotBlock
                               lang={lang}/>}
                    />
                    <Route path="/profile"
                           element={<ProfilePage
                               lang={lang}
                               userI={userI}
                               myAds={myAds}
                               setClickInitiative={setClickInitiative}
                               setResU={setResU}
                               setTabsKey={setTabsKey}
                           />}
                    />
                    <Route path="/edit-profile"
                           element={<EditProfilePage
                               lang={lang}
                               userI={userI}
                               setUserI={setUserI}/>}
                    />
                    <Route path="/rules"
                           element={<RulesBlock
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               setData={setData}
                               setDistChoice={setDistChoice}/>}
                    />
                    <Route path="/about"
                           element={<AboutProject
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               setData={setData}
                               setDistChoice={setDistChoice}/>}
                    />
                    <Route path="/workMap"
                           element={<WorkMapPage
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               setData={setData}
                               setLang={setLang}/>}
                    />
                    <Route path="/initiative-map"
                           element={<IniMapPage
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               dataCat={dataCat}
                               data={!tabBtn ? data?.filter(i => i.status.id === 1) : data}
                               setClickInitiative={setClickInitiative}
                               setData={setData}
                               distChoice={distChoice}
                               setDistChoice={setDistChoice}
                               setMapCatClick={setMapCatClick}
                               tabBtn={tabBtn}
                               dataCardPas={dataCardPas}
                           />}/>


                    <Route path="/change-password/:token"
                           element={<ChangePasBlock
                               lang={lang}/>}
                    />
                    <Route path="/initiative"
                           element={<TableBlock
                               lang={lang}
                               setClickInitiative={setClickInitiative}
                               setCatCardClick={setCatCardClick}
                               data={data}
                               setData={setData}
                               setDistChoice={setDistChoice}
                               tabBtn={tabBtn}
                               setTabsKey={setTabsKey}
                               setResetTable={setResetTable}
                               archiveYears={archiveYears}
                               isYear={isYear}
                               setIsYear={setIsYear}/>}
                    />
                    <Route path="/initiative-moder"
                           element={<TableBlockModer
                               lang={lang}
                               setClickInitiative={setClickInitiative}
                               setCatCardClick={setCatCardClick}
                               data={data}
                               clickInitiative={clickInitiative}
                               setResetTable={setResetTable}
                               setTabsKey={setTabsKey}
                               setData={setData}
                               setDistChoice={setDistChoice}/>}
                    />
                    <Route path="/email-confirm/:token"
                           element={<ConfirmEmail
                               lang={lang}/>}
                    />
                    <Route path="/auth-social"
                           element={<AuthSocial
                               lang={lang} setUserI={setUserI}/>}
                    />
                    <Route path="*"
                           element={<NotPage
                               lang={lang}
                               setCatCardClick={setCatCardClick}
                               setData={setData}
                               setDistChoice={setDistChoice}/>}
                    />

                </Routes>
                <CardInitiativeBlock
                    lang={lang}
                    clickInitiative={clickInitiative}
                    setClickInitiative={setClickInitiative}
                    dataCardPas={dataCardPas}
                    setDataCardPas={setDataCardPas}
                    setResetTable={setResetTable}
                    tabsKey={tabsKey}
                    tabBtn={tabBtn}
                />
                <FooterBlock lang={lang}/>
            </div>
        </ConfigProvider>);
};

export default Initiatives;
