import {Button, Form, Input, notification} from 'antd';
import './LoginBlock.css'
import Google from '../../assets/icons/Google.png'

import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import {lanGs} from '../../utils/lang/langLoginBlock'

const LoginBlock = ({ lang, setUserI, setResU }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [api, contextHolder] = notification.useNotification();
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        },
    };
    const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(location.search);
        return results === null ? '/' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const openNotification = (placement) => {
        switch (placement) {
            case 'USER_NOT_FOUND_ERROR':
                return api.info({
                    description: `${lanGs['Пользователь с таким Email не найден'][lang]}`,
                    placement: 'top',
                });
            case 'USER_NOT_CONFIRMED_ERROR':
                return api.info({
                    description: `${lanGs['Пользователь не подтвердил Email'][lang]}`,
                    placement: 'top',
                });
            case 'BAD_CREDENTIALS_ERROR':
                return api.info({
                    description: `${lanGs['Неверный пароль'][lang]}`,
                    placement: 'top',
                });
            default:
                break;
        }
    };
    const onFinish = (values) => {
        values &&
        axios({
            method: 'post',
            url: "/sc-initiatives/api/v1/auth/login",
            data: {
                email: values.email,
                password: values.password,
            },
            headers: { 'Content-type': 'application/json; charset=UTF-8' },
            cancelToken: values.token,
        })
            .then((res) => {
                Cookies.set('jwt', res.data.token);

                Cookies.set('name', res.data.name);
                Cookies.set('email', res.data.email);
                Cookies.set('roles', res.data.roles);

                axios({
                    method: 'get',
                    url: `/sc-initiatives/api/v1/user/profile`,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        Authorization: `Bearer ${res.data.token}`,
                    },
                })
                    .then((res) => {
                        setResU(true);

                        setUserI(res.data);
                        Cookies.set('UserID', JSON.stringify(res.data));
                    })
                    .catch(function (error) {
                        console.log('ERROR', error)
                    });
                navigate(getUrlParameter('redirect'));
                navigate('/');
            })
            .catch(function (error) {
                openNotification(error.response.data);
            });
    };

        return (
            <div className={'LoginBlock_wrapper'}>
                {contextHolder}
                <div className={'LoginBlock_wrap'}>
                    <div className={'LoginBlock_title_text_wrap'}>
                        <span className={'LoginBlock_title_text'}>{lanGs['Войдите в систему'][lang]}</span>
                        <Button className={'title_text_x'} onClick={() => navigate('/')}>X</Button>
                    </div>

                    <Form name="nest-messages123" onFinish={onFinish}
                        style={{maxWidth: 600,}} validateMessages={validateMessages}
                        layout="vertical"
                        // autoComplete="off"
                    >
                        <div className={' btn_forget_item_wrap'}>
                          <span className={'btn_forget_item'} onClick={() => {navigate('/reg')}}>
                             {lanGs['Зарегистрироваться'][lang]}
                          </span>

                            <Form.Item label="Email" name={'email'} rules={[{required: true, type: 'email', message: `${lanGs['Не заполнено'][lang]}`,},]}
                                       className={'LoginBlock_line'}>
                                <Input className={'Password_type_input'} size="large"/>
                            </Form.Item>
                        </div>
                        <Form.Item
                            label={`${lanGs['Пароль'][lang]}`}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: `${lanGs['Не заполнено'][lang]}`,
                                },
                                {
                                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
                                    message:`${lanGs['Требование к паролю: латиница, цифры, спецсимволы, не менее 6 знаков'][lang]}`                                     ,
                                },
                            ]}
                            className={'LoginBlock_line'}>
                            <Input.Password size="large" className={'Password_type_input'} />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" className={'btn_submit_item'}>{lanGs['Вход'][lang]}</Button>
                        </Form.Item>
                        <Form.Item className={'Item_forget_wrap'}>
                            <a href="#" className={'Item_forget'} onClick={() => navigate('/forgot')}>{lanGs['Забыли пароль?'][lang]}</a>
                        </Form.Item>

                        <div className={'btn_social_btn_wrap'}>
                           {/* <a className={'btn_social_btn'}>
                                <img src={Google} alt="Google_icon"/>
                                <a href={'https://initiative.smartalmaty.kz/sc-initiatives/oauth2/authorize/google?redirect_uri=https://initiative.smartalmaty.kz/auth-social'}>{lanGs['Войти через Gmail'][lang]}</a>
                                <a href={'https://initiative.smartalmaty.kz/sc-initiatives/oauth2/authorize/google?redirect_uri=http://localhost:3000/auth-social'}>{lanGs['Войти через Gmail'][lang]}</a>
                            </a>*/}
                          {/*  <a className={'btn_social_btn '}>
                                <img src={Facebook} alt="facebook_icon"/>
                                <a href={'https://initiative.smartalmaty.kz/sc-initiatives/oauth2/authorize/facebook?redirect_uri=https://initiative.smartalmaty.kz/auth-social'}>{lanGs['Войти через Facebook'][lang]}</a>
                            </a>*/}
                         {/*   <a className={'btn_social_btn'}>
                                <img src={Instagram} alt="Google_icon"/>
                                <span>{lanGs['Войти через Instagram'][lang]}</span>
                            </a>*/}
                        </div>
                       {/* <span className={'LoginBlock_btn_social_text'}>{lanGs['При входе через социальные сети доступно только голосование'][lang]}</span>*/}
                    </Form>
                </div>
            </div>
        )

    }
export default LoginBlock;
